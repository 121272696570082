







import Vue from 'vue'
const baseUrl = 'http://ylwx.sxktqx.com'

import PdfView from './pdf_view.vue'

export default Vue.extend({
    components: {
        PdfView,
    },
    data() {
        return {
            msg: '正在打开文档',
            iframeUrl: '',
            pdfUrl: '',
        }
    },
    mounted() {
        this.loading = true
        const query: any = this.$route.query
        const { url } = query
        if(this.isOffice(url)) {
            const path = `https://view.xdocin.com/xdoc?_xdoc=${baseUrl}${url}`
            this.iframeUrl = path
        } else if(this.isPdf(url)) {
            this.pdfUrl = url
        } else {
            this.msg = '暂不支持该格式'
        }
    },
    methods: {
        isOffice(url: string) {
            return url && (url.endsWith('doc') || url.endsWith('docx') || 
            url.endsWith('ppt') || url.endsWith('pptx') || 
            url.endsWith('xls') || url.endsWith('xlsx'))
        },
        isPdf(url: string) {
            return url && url.endsWith('pdf')
        },
    }
})
